import {Box, IconButton, Stack, Typography} from "@mui/material";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import {grey} from "@mui/material/colors";
import CloseIcon from '@mui/icons-material/Close';
import {largeScreenCart} from "../../css/restaurantPageStyles";
import {URLS} from "../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {clearCart, removeItem} from "../../features/cartSlice";
import React, {useEffect, useState} from "react";
import {checkOtp, checkTableSession, createSession, getSessionStatus} from "../../services/SessionService";
import OtpModal from "../../components/restaurant/otpModal";
import {setHotelSessionId, setSessionId} from "../../features/sessionSlice";
import {createOrder} from "../../services/OrderService";
import socketService from "../../services/SocketService";
import Snackbar from "../../components/snackbar";
import {useTranslation} from "react-i18next";
import {calculateTotalPrice, handleCartDecrement, handleCartIncrement} from "../../utils/cartDataHelpers";
import CartFromOtherLocation from "../../components/cartFromOtherLocation";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Cart = () => {
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: "",
        severity: "success",
    })
    const [cartFromOtherLocation, setCartFromOtherLocation] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otpInput, setOtpInput] = useState("");

    const hotel_session_id = useSelector(
        (state) => state.session.hotel_session_id
    );

    const {cart, totalamount, subtotal, totaltax, table_id, room_id, loc_id, session_date, end_time, session_owner} = useSelector((state) => state.allCart);

    const dispatch = useDispatch();
    const specificRestaurantData = useSelector(
        (state) => state.restaurantData.specificRestaurantData
    );

    useEffect(() => {
        if (!showOtpModal) {
            setLoading(false);
        }
    }, [showOtpModal]);

    const handleClearCart = () => {
        dispatch(clearCart());
    };

    let locationId = specificRestaurantData?.loc_id;

    const handleVerifyOtp = async () => {
        const otpVerificationResponse = await checkOtp({
            location_otp: { location_otp: otpInput },
            loc_id: locationId,
        });

        if (otpVerificationResponse.successStatus) {
            setShowOtpModal(false);
            setOtpInput("");
            try {
                const currentTime = new Date();
                const formattedCurrentTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`;
                const formattedDate = `${currentTime.getUTCFullYear()}-${("0" + (currentTime.getUTCMonth() + 1)).slice(-2)}-${("0" + currentTime.getUTCDate()).slice(-2)}`;
                const location_id = room_id;

                if (!location_id) {
                    throw new Error("Both table_id and room_id are null.");
                }

                const dataToSend = {
                    [room_id && "room_id"]: location_id,
                    location_id: loc_id,
                    session_date,
                    start_time: formattedCurrentTime,
                    end_time,
                    session_owner,
                    hotel_id: specificRestaurantData?.hotel_id,
                };

                const checkRoomRes = await checkTableSession({checkRoom: true, room_id: room_id, location_id: specificRestaurantData.loc_id});

                if (checkRoomRes.data.code === 404) {

                    const sessionId = await createSessionFunction(dataToSend);
                    if (sessionId.error) {
                        return
                    }

                    await createAndHandleOrder(sessionId, formattedCurrentTime, formattedDate, location_id, dataToSend, cart, totalamount, totaltax, loc_id, session_owner);
                } else if (checkRoomRes.data.code === 200) {
                    let sessionId = checkRoomRes.data.sessionInfo.session_id;
                    await createAndHandleOrder(sessionId, formattedCurrentTime, formattedDate, location_id, dataToSend, cart, totalamount, totaltax, loc_id, session_owner);
                } else {
                    setSnackbarParams({
                        open: true,
                        severity: 'error',
                        message: "Error While Checking for Session ID",
                    })
                }
            } catch (error) {
                console.error(error);
                setSnackbarParams({
                    open: true,
                    message: error.message,
                    severity: "error",
                });
            }
        } else {
            // Handle incorrect OTP
            setSnackbarParams({
                open: true,
                message: "Incorrect OTP",
                severity: "error",
            })
        }
    }

    const createSessionFunction = async (dataToSend) => {
        const response = await createSession(dataToSend);

        if (response.status === 500) {
            console.warn("Session API Error", response.message, response.status);
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: response.message,
            })
            return;
        }

        const sessionId = response.SessionDetail.session_id;
        const hotelSessionCheck = response.SessionDetail.hotel_id;
        if (hotelSessionCheck === 0) {
            dispatch(setHotelSessionId(sessionId));
        } else {
            dispatch(setSessionId(sessionId));
        }

        return sessionId
    };

    const createAndHandleOrder = async (sessionId, formattedCurrentTime, formattedDate, location_id, dataToSend, cart, totalamount, totaltax, loc_id, session_owner, t, socketService) => {
        const orderData = {
            user_id: null,
            table_no: location_id === table_id ? location_id : null,
            session_id: sessionId,
            total_items: cart.length,
            hotel_id: room_id ? specificRestaurantData?.hotel_id : null,
            room_id: location_id === room_id ? location_id : null,
            order_time: formattedCurrentTime,
            order_date: formattedDate,
            start_time: formattedCurrentTime,
            order_type_id: 2,
            order_variant: "small",
            discount_id: 1,
            total_discount: 0,
            totalPrice: totalamount,
            comment: "",
            qrcode: "",
            qrcodedata: "",
            promocode_id: null,
            payment_status_id: 2,
            order_tax: totaltax,
            location_id: loc_id,
            session_owner,
            menu_items: cart,
        };

        const orderResponse = await createOrder(orderData);
        if (orderResponse) {
            socketService.emit("order_placed", {
                order_id: orderResponse?.order?.order_id,
                location_id: locationId,
            });
        }
        setLoading(false);
        setSnackbarParams({
            open: true,
            message: t("order-placed-successfully-1"),
            severity: "success",
        })
        handleClearCart();
    };

    const handleAPICall = async () => {
        if ((specificRestaurantData.loc_id !== loc_id) && cart.length > 0) {
            setCartFromOtherLocation(true)
        } else {
            setLoading(true);
            try {
                const currentTime = new Date();
                const currentHours = currentTime.getHours();
                const currentMinutes = currentTime.getMinutes();
                const currentSeconds = currentTime.getSeconds();
                const formattedCurrentTime = `${currentHours}:${currentMinutes}:${currentSeconds}`;
                const year = currentTime.getUTCFullYear();
                const month = ("0" + (currentTime.getUTCMonth() + 1)).slice(-2);
                const day = ("0" + currentTime.getUTCDate()).slice(-2);

                const formattedDate = `${year}-${month}-${day}`;
                setShowOtpModal(true);

            } catch (error) {
                setLoading(false);
                setSnackbarParams({
                    open: true,
                    message: "Error Placing Order. Table is already booked",
                    severity: "error",
                })
                console.error("Error sending data to API:", error);
            }
        }
    };

    const handleRemoveItem = (itemIndex) => {
        const remainItems = cart.filter((item, index) => index !== itemIndex);
        dispatch(removeItem({ itemIndex, items: remainItems }));
    };

    const handleIncrement = (itemIndex) => {
        const updatedCart = handleCartIncrement(itemIndex, cart)

        dispatch(removeItem({ itemIndex, items: updatedCart }));
    };

    const handleDecrement = (itemIndex) => {
        const updatedCart = handleCartDecrement(itemIndex, cart)

        dispatch(removeItem({ itemIndex, items: updatedCart }));
    };

    return(
        <Stack sx={{backgroundColor: 'white'}}>
            <Typography vairant='body2' sx={{fontSize: '16px', fontWeight: '600', padding: '1rem'}}>Cart</Typography>
            {
                (cart.length > 0) ? (
                    <Stack sx={{rowGap: '1rem'}}>
                        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                            <Typography variant='body2' sx={largeScreenCart.summaryText}>Summary</Typography>
                            <Typography variant='body2' sx={largeScreenCart.clearButton} onClick={()=> handleClearCart()}>Clear</Typography>
                        </Stack>
                        <Stack sx={{rowGap: '1rem', height: 'calc(100vh - 236px)', overflow: 'auto'}}>
                            {
                                cart.map((item, index)=> (
                                    <Stack key={index} direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Stack direction='row' sx={{columnGap: {md: '0.5rem', lg: '1rem'}}}>
                                            <Box component='img' src={`${URLS.BaseUrlWithoutWebsite + "/" + item.menu_photo}`} sx={largeScreenCart.itemImage}/>
                                            <Stack sx={{rowGap: '0.25rem'}}>
                                                <Typography variant='body2' sx={largeScreenCart.itemNameText}>{item.menu_name}</Typography>
                                                <Typography variant='body2' sx={largeScreenCart.itemPriceText}>{specificRestaurantData.Currency.currency_symbol} {item.menu_price}</Typography>
                                                <Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center'}}>
                                                    <IconButton size='small' sx={{backgroundColor: grey[500], '&:hover': {backgroundColor: grey[700]}}} onClick={() => handleIncrement(index)}>
                                                        <AddIcon sx={{height: '15px', width: '15px', color: 'white'}} />
                                                    </IconButton>
                                                    <Typography variant='body2' sx={{fontSize: '16px', fontWeight: '600'}}>
                                                        {item.itemCount}
                                                    </Typography>
                                                    <IconButton size='small' sx={{backgroundColor: grey[500], '&:hover': {backgroundColor: grey[700]}}} onClick={() => handleDecrement(index)}>
                                                        <RemoveIcon sx={{height: '15px', width: '15px', color: 'white'}} onClick={()=> handleRemoveItem(index)}/>
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Stack sx={{rowGap: '0.5rem', alignItems: 'center'}}>
                                            <IconButton size='small' sx={{backgroundColor: grey[500], width: 'fit-content'}} onClick={() => handleRemoveItem(index)}>
                                                <CloseIcon sx={largeScreenCart.iconStyle}/>
                                            </IconButton>
                                            <Typography variant='body2' sx={{fontSize: {md: '12px', lg: '14px'}, fontWeight: '400'}}>{
                                                specificRestaurantData.Currency.currency_symbol +
                                                calculateTotalPrice(
                                                    item.menu_price,
                                                    item.menu_tax_percentage,
                                                    item.itemCount
                                                )}</Typography>
                                        </Stack>
                                    </Stack>
                                ))
                            }
                        </Stack>
                        <Box sx={{height: '1px', backgroundColor: grey[300], width :'100%'}} />
                        <Stack direction='row' sx={largeScreenCart.checkoutButton} onClick={()=> handleAPICall()}>
                            <Typography variant='body2' sx={largeScreenCart.checkoutButtonText}>
                                Place Order
                            </Typography>
                            <Typography variant='body2' sx={largeScreenCart.checkoutButtonText}>
                                {specificRestaurantData.Currency.currency_symbol} {totalamount.toFixed(2)}
                            </Typography>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack sx={{alignItems: 'center', justifyContent: 'center', marginTop: '2rem'}}>
                        <LocalMallIcon sx={{height: '50px', width: '50px', color: 'black', marginBottom: '0.5rem'}} />
                        <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '600'}}>You don't have any orders here!</Typography>
                        <Typography variant='body2' sx={{fontSize: '12px', fontWeight: '400'}}>Let's change that</Typography>
                    </Stack>
                )
            }
            { showOtpModal && <OtpModal setShowOtpModal={setShowOtpModal} showOtpModal={showOtpModal} otpInput={otpInput} setOtpInput={setOtpInput} handleVerifyOtp={handleVerifyOtp}/> }
            <Snackbar setSnackbarParam={setSnackbarParams} snackbarParams={snackbarParams} />
            { cartFromOtherLocation && <CartFromOtherLocation setCartFromOtherLocation={setCartFromOtherLocation} cartFromOtherLocation={cartFromOtherLocation} /> }
        </Stack>
    )
}

export default Cart