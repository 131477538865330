import {ApiRoute, CONSTANTS} from "../utils/constants";
import axios from "axios";

export const createSession = async ({
  table_id,
  location_id,
  session_date,
  start_time,
  end_time,
  table_name,
  session_owner,
  room_id,
  hotel_id,
}) => {
  let data = {
    table_id,
    location_id,
    session_date,
    start_time,
    end_time,
    table_name,
    session_owner,
    room_id,
    hotel_id,
    order_from: "QR Scan"
  };

  let response = await fetch(`${ApiRoute.createSession}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  console.warn("____", response.status);
  let statusCodedc = response.status;
  response = await response.json();
  response.status = statusCodedc;
  if (response) return response;
  throw new Error(`Could not Create Session: ${response}`);
};

export const closeSession = async ({
  session_id,
  location_id,
  table_id,
  end_time,
  session_status,
  room_id,
  hotel_id,
}) => {
  let data = {
    session_id,
    location_id,
    table_id,
    end_time,
    session_status,
    room_id,
    hotel_id,
  };

  let response = await fetch(`${ApiRoute.closeSession}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });

  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Close Session: ${response}`);
};


export const getSessionStatus = async (session_id) => {
  try {
    const response = await fetch(`${ApiRoute.getSessionStatus(session_id)}`, {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS },
    });

    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      throw new Error(`Failed to fetch session status: ${data.message}`);
    }
  } catch (error) {
    throw new Error(
      `An error occurred while fetching session status: ${error.message}`
    );
  }
};

export const checkOtp = async ({ location_otp, loc_id }) => {
  try {
    const response = await fetch(`${ApiRoute.verifyOtp(loc_id)}`, {
      method: "POST",
      body: JSON.stringify(location_otp),
      headers: { ...CONSTANTS.REQUEST_HEADERS },
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error(`Could not verify OTP: ${error.message}`);
  }
};

export const paymentRequest = async ({ session_id, table_id }) => {
  try {
    let data = { session_id, table_id };
    const response = await fetch(`${ApiRoute.paymentRequest}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { ...CONSTANTS.REQUEST_HEADERS },
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw new Error(`Could not verify OTP: ${error.message}`);
  }
};

export const checkTableSession = async (data) => {
  try {
    const response = await axios.post(`${ApiRoute.checkTableSession}`, data, {
      headers: { ...CONSTANTS.REQUEST_HEADERS }
    });

    if (!response || !response.data) {
      return ({error: true, message: 'Cannot Check Table Session'})
    } else {
      return ({error: false, data: response.data})
    }
  } catch (err) {
    return ({error: true, message: 'Cannot Check Table Session', data: { code: 500 }})
  }
}
