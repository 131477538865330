import TopInformation from "../sections/hotel_services/topInformation";
import SearchSection from "../sections/hotel_services/searchSection";
import {Box, Button, Container, Slide, Stack, Tab, Tabs, Typography, useMediaQuery, useTheme} from "@mui/material";
import {cartMainBox, cartSmallScreenButton, responsiveStack, searchSection} from "../css/restaurantPageStyles";
import React, {useEffect, useState} from "react";
import MenuSection from "../sections/hotel_services/menuSection";
import Cart from "../sections/hotel_services/cart";
import MobileCart from "../sections/hotel_services/mblCart";
import {RestaurantData, RestaurantMenuData} from "../features/restaurantSlice";
import {getSessionRoom} from "../services/MenuService";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../components/Loader";
import History from "../sections/hotel_services/history";
import {MAIN_GREEN_COLOR} from "../colors";
import {getRoomId} from "../features/cartSlice";
import {setHotelSessionId, setSessionId} from "../features/sessionSlice";
import SnackBar from "../components/snackbar";
import {CONSTANTS, URLS} from "../utils/constants";
import {setOpenCartDrawer} from "../features/variablesSlice";

const HotelServices = () => {
    const restaurantData = useSelector(state => state.restaurantData.specificRestaurantData)
    const [switchSelection, setSwitchSelection] = useState('cart')
    const [selectedTabCategory, setSelectedTabCategory] = useState(1);
    const [selectedOption, setSelectedOption] = useState(1);
    const location = useLocation();
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        severity: 'success',
        message: '',
    });
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()

    const {cart} = useSelector((state) => state.allCart);
    const dispatch = useDispatch()
    const {slug} = useParams()

    const fetchSessionData = () => {
        // Extract room_id from URL parameters
        const params = new URLSearchParams(location.search);
        const roomIdFromParams = params.get("room_id");

        const restaurantParam = location.pathname;

        // Update localStorage when roomIdFromParams is present
        if (roomIdFromParams) {
            // localStorage.setItem("roomId", roomIdFromParams);
            dispatch(getRoomId(roomIdFromParams));
            getSessionRoom(roomIdFromParams, restaurantData?.loc_id).then((data) => {
                // dispatch(setSessionId(data.session_id));
                const hotelSessionCheck = data?.hotel_id;
                if (restaurantParam.includes("/services/")) {
                    // dispatch(setHotelSessionId(data.session_id));
                    dispatch(setHotelSessionId(data.session_id));
                    dispatch(setSessionId(null));
                }
            }).catch((error) => {
                console.error("Error fetching session data:", error);
            });
        }
    }

    const fetchLocations = async () => {
        const BASE_URL = `${URLS.BaseUrl}hotel/details/`;

        const response = await fetch(`${BASE_URL}${slug}`, {
            headers: CONSTANTS.REQUEST_HEADERS,
        });
        try {
            const data = await response.json();
            if (!response.ok) {
                // throw new Error("Failed to fetch location details");
            }
            dispatch(RestaurantMenuData(data?.categoriesWithItems));
            dispatch(RestaurantData(data?.hotel));

            setLoading(false);
        } catch (error) {
            console.error(error)
        }
    };

    useEffect(() => {
        fetchLocations()
    }, [slug]);

    useEffect(() => {
        fetchSessionData()
    }, [slug, location]);

    const handleChange = (event, newValue) => {
        setSwitchSelection(newValue)
    }

    return(
        (loading ? (
            <Loader />
        ) : (!isError ?
                <>
                    <TopInformation/>
                    <Container maxWidth='xl' sx={{padding: {xs: '1rem', md: '2rem'}}}>
                        <Stack direction='row' columnGap='0.75rem' sx={responsiveStack}>
                            <Stack rowGap='1rem'>
                                <SearchSection selectedTab={selectedTabCategory} setSelectedTabCategory={setSelectedTabCategory}/>
                                <MenuSection setSelectedTab={setSelectedTabCategory}/>
                            </Stack>
                            {sm ?
                                <MobileCart/>
                                :
                                <Box>
                                    <Stack sx={cartMainBox}>
                                        <Stack direction='row' sx={{alignItems: 'center', columnGap: '1rem'}}>
                                            <Tabs
                                                value={switchSelection}
                                                onChange={handleChange}
                                                sx={searchSection.tabsStyle} variant={'fullWidth'}>
                                                <Tab label={'Cart'} key={1} value={'cart'} sx={searchSection.tabStyle}/>
                                                <Tab label={'History'} key={2} value={'history'} sx={searchSection.tabStyle}/>
                                            </Tabs>
                                        </Stack>
                                        {
                                            switchSelection === 'history' ? (<History />) : (<Cart />)
                                        }
                                    </Stack>
                                </Box>
                            }
                        </Stack>
                    </Container>
                    {sm &&
                        <Slide direction='up' in={cart.length > 0 && sm} timeout={500}>
                            <Stack direction='row' onClick={()=> dispatch(setOpenCartDrawer(true))} sx={cartSmallScreenButton}>
                                <Stack sx={{marginLeft: '1rem'}}>
                                    <Typography variant='body2' sx={{color: 'white', fontSize: '12px'}}>View Order</Typography>
                                    <Typography variant='body2' sx={{color: 'white', fontSize: '14px', fontWeight: '600px'}}>{restaurantData.name}</Typography>
                                </Stack>
                                <Typography variant='body2' sx={{marginRight: '1rem', color: 'white', fontSize: '14px', fontWeight: '600px'}}>{cart.length}</Typography>
                            </Stack>
                        </Slide>
                    }

                    <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
                </> : <Stack sx={{height: '90vh', width: '100vw', alignItems: 'center', justifyContent: 'center', rowGap: '1rem'}}>
                    <Typography variant='h3' sx={{textAlign: 'center', fontWeight: '400', width: {xs: '90vw', sm: '500px', md: '850px', lg: '1100px', xl: '1400px'}}}>
                        You have no right to access this module. Please first purchase it.
                    </Typography>
                    <Button variant={'contained'} sx={{backgroundColor: MAIN_GREEN_COLOR}} onClick={() => navigate('/')}>Go to Home</Button>
                </Stack>
        ))
    )
}

export default HotelServices