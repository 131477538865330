import {Box, Divider, IconButton, Stack, Typography} from "@mui/material";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {grey} from "@mui/material/colors";
import CloseIcon from '@mui/icons-material/Close';
import {largeScreenCart} from "../../css/restaurantPageStyles";
import {URLS} from "../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {clearCart, removeItem} from "../../features/cartSlice";
import React, {useState} from "react";
import {checkOtp, checkTableSession, createSession, getSessionStatus} from "../../services/SessionService";
import OtpModal from "../../components/restaurant/otpModal";
import {setHotelSessionId, setSessionId} from "../../features/sessionSlice";
import {createOrder, modifyOrder} from "../../services/OrderService";
import socketService from "../../services/SocketService";
import Snackbar from "../../components/snackbar";
import {useTranslation} from "react-i18next";
import {calculateTotalPrice, handleCartDecrement, handleCartIncrement} from "../../utils/cartDataHelpers";
import CartFromOtherLocation from "../../components/cartFromOtherLocation";
import placeholderImage from "../../assets/placeholder_restaurant.png";

const Cart = () => {
    const {t} = useTranslation("global");
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        severity: 'success',
        message: '',
    });

    const [cartFromOtherLocation, setCartFromOtherLocation] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otpInput, setOtpInput] = useState("");
    let session_id = useSelector((state) => state.session.session_id);

    const {cart, totalamount, totaltax, table_id, room_id, loc_id, session_date, end_time, session_owner, table_name,} = useSelector((state) => state.allCart);

    console.log("Room ID", room_id);
    console.log("Table ID", table_id);
    const dispatch = useDispatch();
    const specificRestaurantData = useSelector((state) => state.restaurantData.specificRestaurantData);

    const handleClearCart = () => {
        dispatch(clearCart());
    };

    let locationId = specificRestaurantData?.loc_id;

    const handleVerifyOtp = async () => {
        if (specificRestaurantData.use_otp) {
            const otpVerificationResponse = await checkOtp({
                location_otp: {location_otp: otpInput},
                loc_id: locationId,
            });

            if (!otpVerificationResponse.successStatus) {
                setSnackbarParams({
                    open: true,
                    message: "In-Valid Otp",
                    severity: 'error'
                })
                return
            }

            setShowOtpModal(false);
            setOtpInput("");
        }

        try {
            const currentTime = new Date();
            const formattedCurrentTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`;
            const formattedDate = `${currentTime.getUTCFullYear()}-${("0" + (currentTime.getUTCMonth() + 1)).slice(-2)}-${("0" + currentTime.getUTCDate()).slice(-2)}`;
            const location_id = table_id || room_id;

            if (!location_id) {
                throw new Error("Both table_id and room_id are null.");
            }

            const dataToSend = {
                [table_id ? "table_id" : "room_id"]: location_id,
                location_id: loc_id,
                session_date,
                start_time: formattedCurrentTime,
                end_time,
                session_owner,
                hotel_id: specificRestaurantData?.hotel_id,
            };
            if (table_id) {
                dataToSend.table_name = table_name;
            }

            let checkSessionRes = null
            if (table_id) {
                checkSessionRes = await checkTableSession({
                    checkTable: true,
                    table_id: table_id,
                    location_id: specificRestaurantData.loc_id
                });
            } else {
                checkSessionRes = await checkTableSession({
                    checkRoom: true,
                    room_id: room_id,
                    location_id: specificRestaurantData.loc_id
                });
            }

            if (checkSessionRes.data.code === 404) {

                const sessionId = await createSessionFunction(dataToSend);
                if (sessionId.error) {
                    return
                }
                await createAndHandleOrder(sessionId.sessionId, formattedCurrentTime, formattedDate, location_id, dataToSend, cart, totalamount, totaltax, loc_id, session_owner);
            } else if (checkSessionRes.data.code === 200) {
                let sessionId = checkSessionRes.data.sessionInfo.session_id;
                if (specificRestaurantData.combine_items) {
                    const {code} = await modifyPreviousOrder(sessionId, cart, totalamount, totaltax);
                    if (code === 302) {
                        await createAndHandleOrder(sessionId, formattedCurrentTime, formattedDate, location_id, dataToSend, cart, totalamount, totaltax, loc_id, session_owner);
                    }
                } else {
                    await createAndHandleOrder(sessionId, formattedCurrentTime, formattedDate, location_id, dataToSend, cart, totalamount, totaltax, loc_id, session_owner);
                }
            } else {
                setSnackbarParams({
                    open: true,
                    severity: 'error',
                    message: "Error While Checking for Session ID",
                })
            }
        } catch (error) {
            //(error);
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: error.message,
            })
        }
    }

    const createSessionFunction = async (dataToSend) => {
        const response = await createSession(dataToSend);
        if (response.status === 500) {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: response.message,
            })
            return {error: true};
        }

        const sessionId = response.SessionDetail.session_id;
        const hotelSessionCheck = response.SessionDetail.hotel_id;
        if (hotelSessionCheck === 0) {
            dispatch(setHotelSessionId(sessionId));
        } else {
            dispatch(setSessionId(sessionId));
        }

        return {error: false, sessionId: sessionId}
    };

    const createAndHandleOrder = async (sessionId, formattedCurrentTime, formattedDate, location_id, dataToSend, cart, totalamount, totaltax, loc_id, session_owner) => {
        const orderData = {
            user_id: null,
            table_no: location_id === table_id ? location_id : null,
            session_id: sessionId,
            total_items: cart.length,
            hotel_id: room_id ? specificRestaurantData?.hotel_id : null,
            room_id: location_id === room_id ? location_id : null,
            order_time: formattedCurrentTime,
            order_date: formattedDate,
            start_time: formattedCurrentTime,
            order_type_id: 2,
            order_variant: "small",
            discount_id: 1,
            total_discount: 0,
            totalPrice: totalamount,
            comment: "",
            qrcode: "",
            qrcodedata: "",
            promocode_id: null,
            payment_status_id: 2,
            order_tax: totaltax,
            location_id: loc_id,
            session_owner,
            menu_items: cart,
        };

        const orderResponse = await createOrder(orderData);
        if (orderResponse) {
            socketService.emit("order_placed", {
                order_id: orderResponse?.order?.order_id,
                location_id: locationId,
            });
        }

        setSnackbarParams({
            open: true,
            message: "Order Placed Successfully",
            severity: "success",
        })
        handleClearCart();
    };

    const modifyPreviousOrder = async (sessionId, cart, totalamount, totaltax) => {
        const data = {
            order_total: totalamount,
            order_tax: totaltax,
            menu_items: cart
        }

        const response = await modifyOrder(sessionId, data);
        if (response.error) {
            setSnackbarParams({
                open: true,
                message: "Error Placing Order. Table is already booked",
                severity: "error",
            })
        } else {
            if (response.data.code === 200) {
                setSnackbarParams({
                    open: true,
                    message: "Order Placed Successfully",
                    severity: "success",
                })
                socketService.emit("order_placed", {
                    order_id: response.data.order_id,
                    location_id: locationId,
                });

                handleClearCart();
                return {code: 200}
            } else {
                return {code: 302}
            }
        }
    }

    const handleAPICall = async () => {
        if ((specificRestaurantData.loc_id !== loc_id) && cart.length > 0) {
            setCartFromOtherLocation(true)
        } else {
            try {
                if (specificRestaurantData.use_otp) {
                    setShowOtpModal(true);
                } else {
                    await handleVerifyOtp();
                }
            } catch (error) {
                setSnackbarParams({
                    open: true,
                    message: "Error Placing Order. Table is already booked",
                    severity: "error",
                })
                console.error("Error sending data to API:", error);
            }
        }
    };

    const handleIncrement = (itemIndex) => {
        const updatedCart = handleCartIncrement(itemIndex, cart);

        dispatch(removeItem({ itemIndex, items: updatedCart }));
    };

    const handleDecrement = (itemIndex) => {
        const updatedCart = handleCartDecrement(itemIndex, cart);

        dispatch(removeItem({ itemIndex, items: updatedCart }));
    };

    const handleRemoveItem = (itemIndex) => {
        let remainItems = [];
        cart.map((item, index) => {
            if (index !== itemIndex) {
                remainItems.push(item);
            }
        });

        dispatch(removeItem({ itemIndex, items: remainItems }));
    };

    const calculateVariantPrice = (optionPrice, taxPercent, itemCount) => {
        const itemPrice = optionPrice || 0;
        const tax = taxPercent || 0;
        const variantPrice =
            tax > 0
                ? itemPrice * itemCount * (tax / 100) + itemPrice * itemCount
                : itemPrice * itemCount;

        return variantPrice;
    };

    const handleImageError = (event) => {
        event.target.src = placeholderImage;
    }

    return(
        <Stack sx={{backgroundColor: 'white'}}>
            <Typography vairant='body2' sx={{fontSize: '16px', fontWeight: '600', padding: '1rem'}}>Cart</Typography>
            {
                (cart.length > 0) ? (
                    <Stack sx={{rowGap: '1rem'}}>
                        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                            <Typography variant='body2' sx={largeScreenCart.summaryText}>Summary</Typography>
                            <Typography variant='body2' sx={largeScreenCart.clearButton} onClick={()=> handleClearCart()}>Clear</Typography>
                        </Stack>
                        <Stack sx={{rowGap: '1rem', height: 'calc(100vh - 236px)', overflow: 'auto'}}>
                            {
                                cart.map((item, index)=> (
                                    <Stack key={index} direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Stack direction='row' sx={{columnGap: {md: '0.5rem', lg: '1rem'}}} alignItems='center'>
                                            <Box onError={handleImageError} component='img' src={`${URLS.BaseUrlWithoutWebsite + "/" + item.menu_photo}`} sx={largeScreenCart.itemImage}/>
                                            <Stack sx={{rowGap: '0.25rem'}}>
                                                <Typography variant='body2' sx={largeScreenCart.itemNameText}>{item.menu_name}</Typography>
                                                <Typography variant='body2' sx={largeScreenCart.itemPriceText}>{specificRestaurantData.currency} {item.menu_price}</Typography>
                                                <Stack sx={{rowGap: '0.125rem'}}>
                                                    {
                                                        item.MenuOptions.length > 0 && (item.MenuOptions.map((menu_option, index) => (
                                                            <React.Fragment key={menu_option.option_id}>
                                                                {index !== 0 && <Divider />}
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    sx={{fontSize: '0.75rem', fontWeight: 'bold'}}
                                                                >
                                                                    {menu_option.option_name}
                                                                </Typography>
                                                                {menu_option.Option_Values.map(
                                                                    (menu_option_value) => (
                                                                        <Typography
                                                                            key={menu_option_value.option_id}
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                            sx={{fontSize: '0.7rem', textTransform: 'capitalize'}}
                                                                        >
                                                                            {`${menu_option_value.optionCount}x ${
                                                                                menu_option_value.value
                                                                            } = ${
                                                                                menu_option_value.price === 0
                                                                                    ? "Free"
                                                                                    : specificRestaurantData?.currency +
                                                                                    calculateVariantPrice(
                                                                                        menu_option_value.price,
                                                                                        menu_option_value.order_item_tax_percentage,
                                                                                        menu_option_value.optionCount
                                                                                    ).toFixed(2)
                                                                            }`}
                                                                        </Typography>
                                                                    ))}
                                                            </React.Fragment>
                                                        )))
                                                    }
                                                </Stack>
                                                <Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center'}}>
                                                    <IconButton size='small' sx={{backgroundColor: grey[500], '&:hover': {backgroundColor: grey[700]}}} onClick={() => handleIncrement(index)}>
                                                        <AddIcon sx={largeScreenCart.iconStyle} />
                                                    </IconButton>
                                                    <Typography variant='body2' sx={{fontSize: '16px', fontWeight: '600'}}>
                                                        {item.itemCount}
                                                    </Typography>
                                                    <IconButton size='small' sx={{backgroundColor: grey[500], '&:hover': {backgroundColor: grey[700]}}} onClick={() => handleDecrement(index)}>
                                                        <RemoveIcon sx={largeScreenCart.iconStyle} />
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Stack sx={{rowGap: '0.5rem', alignItems: 'center'}}>
                                            <IconButton size='small' sx={{backgroundColor: grey[500], '&:hover': {backgroundColor: grey[700]}, width: 'fit-content'}} onClick={() => handleRemoveItem(index)}>
                                                <CloseIcon sx={largeScreenCart.iconStyle}/>
                                            </IconButton>
                                            <Typography variant='body2' sx={{fontSize: {md: '12px', lg: '14px'}, fontWeight: '400'}}>{
                                                specificRestaurantData?.currency +
                                                calculateTotalPrice(
                                                    item.menu_price,
                                                    item.menu_tax_percentage,
                                                    item.itemCount
                                                )}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                ))
                            }
                        </Stack>
                        <Box sx={{height: '1px', backgroundColor: grey[300], width :'100%'}} />
                        <Stack direction='row' sx={largeScreenCart.checkoutButton} onClick={()=> handleAPICall()}>
                            <Typography variant='body2' sx={largeScreenCart.checkoutButtonText}>
                                Place Order
                            </Typography>
                            <Typography variant='body2' sx={largeScreenCart.checkoutButtonText}>
                                {specificRestaurantData.currency} {totalamount.toFixed(2)}
                            </Typography>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack sx={{alignItems: 'center', justifyContent: 'center', marginTop: '2rem'}}>
                        <LocalMallIcon sx={{height: '50px', width: '50px', color: 'black', marginBottom: '0.5rem'}} />
                        <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '600'}}>You don't have any orders here!</Typography>
                        <Typography variant='body2' sx={{fontSize: '12px', fontWeight: '400'}}>Let's change that</Typography>
                    </Stack>
                )
            }
            { showOtpModal && <OtpModal setShowOtpModal={setShowOtpModal} showOtpModal={showOtpModal} otpInput={otpInput} setOtpInput={setOtpInput} handleVerifyOtp={handleVerifyOtp}/> }
            <Snackbar setSnackbarParam={setSnackbarParams} snackbarParams={snackbarParams} />
            { cartFromOtherLocation && <CartFromOtherLocation setCartFromOtherLocation={setCartFromOtherLocation} cartFromOtherLocation={cartFromOtherLocation} /> }
        </Stack>
    )
}

export default Cart
