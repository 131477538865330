import {Box, Divider, Stack, Typography} from "@mui/material";
import HistoryIcon from '@mui/icons-material/History';
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {grey} from "@mui/material/colors";
import {URLS} from "../../utils/constants";
import fetchData from "../../utils/fetchDataHelpers";
import {useLocation} from "react-router-dom";
import {calculateTotalPrice, calculateVariantPrice} from "../../utils/cartDataHelpers";
import {getSurveyQuestions} from "../../services/surveyServices";
import SurveyModal from "../../components/surveyModal";
import SnackBar from "../../components/snackbar";
import {largeScreenCart} from "../../css/restaurantPageStyles";
import {checkTableSession} from "../../services/SessionService";

const History = () => {
    const [orderTotal, setOrderTotal] = useState(0)
    const [sessionInfo, setSessionInfo] = useState({
        locationHistory: null,
        orderHistory: [],
        currency: [],
        orderItem: null
    })
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const [openSurveyModal, setOpenSurveyModal] = useState(false);
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        message: "",
        severity: "",
    })
    const [session_id, setSessionID] = useState(null);

    const location = useLocation()
    const specificRestaurantData = useSelector((state) => state.restaurantData.specificRestaurantData);
    const {table_id} = useSelector((state) => state.allCart);

    const getHistoryData = async () => {
        const checkTableRes = await checkTableSession({
            checkTable: true,
            table_id: table_id,
            location_id: specificRestaurantData.loc_id
        });
        if (checkTableRes.data.code === 200) {
            let session_id = checkTableRes.data.sessionInfo.session_id;
            setSessionID(session_id);
            const {sessionHistory, total, error} = await fetchData(location, session_id, null);
            if (!error) {
                setOrderTotal(total)
                setSessionInfo({...sessionHistory})
            }
        }
    }

        const getLocationSurvey = async () => {
            const response = await getSurveyQuestions(specificRestaurantData.loc_id);
            if (!response.error) {
                setSurveyQuestions(response.data);
            } else {

            }
        }

        useEffect( () => {
            getHistoryData()
            getLocationSurvey()
        }, [session_id]);

        const handleSurveyModal = () => {
            if (session_id && session_id !== '') {
                setOpenSurveyModal(true)
            } else {
                setSnackbarParams({
                    open: true,
                    message: 'No Session ID Found',
                    severity: 'error',
                })
            }
        }

        return(
            <>
                <Stack sx={{justifyContent: 'space-between'}}>
                    <Stack>
                        <Typography vairant='body2' sx={{fontSize: '16px', fontWeight: '600', padding: '1rem'}}>History</Typography>
                        {
                            sessionInfo.orderHistory.length > 0 ? (
                                <>
                                    <Stack>
                                        <Typography variant='body2' sx={{fontSize: '12px', fontWeight: '500'}}>Your history from</Typography>
                                        <Stack>
                                            <Typography variant='h6' sx={{fontSize: '16px', fontWeight: '600'}}>{specificRestaurantData.name}</Typography>
                                            <Typography variant='body2' sx={{fontSize: '12px', fontWeight: '500', color: grey[600]}}>{specificRestaurantData.formattedAddress}</Typography>
                                        </Stack>
                                        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Typography variant='body2' sx={{fontSize: '15px', fontWeight: '600'}}>Summary</Typography>
                                            <Typography variant='body2' sx={{fontSize: '15px', fontWeight: '600'}}>{specificRestaurantData.currency} {orderTotal.toFixed(2)}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack sx={{alignItems: 'space-between', justifyContent: 'center', rowGap: '0rem'}}>
                                        {
                                            sessionInfo.orderHistory.map((item, index)=> (
                                                item.OrderMenus.map((intItem, index)=> (
                                                    <Stack key={index} direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', borderBottom: `1px solid ${grey[300]}`, paddingY: '1rem'}}>
                                                        <Stack direction='row' sx={{columnGap: '1rem', alignItems: 'center'}}>
                                                            <Box component='img' src={`${URLS.BaseUrlWithoutWebsite + "/" + intItem.Menu.menu_photo}`} sx={{height: '50px', width: '50px', borderRadius: '5px'}}/>
                                                            <Stack sx={{rowGap: '0.25rem'}}>
                                                                <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '600'}}>{intItem.name}</Typography>
                                                                <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '400'}}>{specificRestaurantData.currency} {intItem.price.toFixed(2)}</Typography>
                                                                <Typography variant='body2' sx={{fontSize: '16px', fontWeight: '600'}}>
                                                                    {intItem.quantity} x items
                                                                </Typography>
                                                                <Stack sx={{rowGap: '0.125rem'}}>
                                                                    {
                                                                        intItem.OrderOptions.length > 0 && (intItem.OrderOptions.map((menu_option, index) => (
                                                                            <React.Fragment key={menu_option.option_id}>
                                                                                <Typography
                                                                                    key={menu_option.option_id}
                                                                                    variant="body2"
                                                                                    color="textSecondary"
                                                                                    sx={{fontSize: '0.7rem', textTransform: 'capitalize'}}
                                                                                >
                                                                                    {`${menu_option.quantity}x ${
                                                                                        menu_option.order_option_name
                                                                                    } = ${
                                                                                        menu_option.order_option_price === 0
                                                                                            ? "Free"
                                                                                            : specificRestaurantData?.currency +
                                                                                            calculateVariantPrice(
                                                                                                menu_option.order_option_price,
                                                                                                menu_option.order_item_tax_percentage,
                                                                                                menu_option.quantity
                                                                                            ).toFixed(2)
                                                                                    }`}
                                                                                </Typography>
                                                                            </React.Fragment>
                                                                        )))
                                                                    }
                                                                </Stack>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack sx={{rowGap: '0.5rem', alignItems: 'center'}}>
                                                            <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '400'}}>
                                                                {specificRestaurantData?.currency +
                                                                    calculateTotalPrice(
                                                                        intItem.price,
                                                                        intItem.menu_tax_percentage,
                                                                        intItem.quantity
                                                                    )}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                ))))
                                        }
                                    </Stack>
                                </>) : (
                                <Stack sx={{alignItems: 'center', justifyContent: 'center', marginTop: '2rem'}}>
                                    <HistoryIcon sx={{height: '50px', width: '50px', color: 'black', marginBottom: '0.5rem'}} />
                                    <Typography variant='body2' sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>You don't have any orders history here!</Typography>
                                    <Typography variant='body2' sx={{fontSize: '12px', fontWeight: '400'}}>Let's change that</Typography>
                                </Stack>
                            )
                        }
                    </Stack>
                    <Stack direction='row' sx={{...largeScreenCart.checkoutButton, marginTop: '1rem', alignItems: 'center', justifyContent: 'center'}} onClick={()=> handleSurveyModal()}>
                        <Typography variant='body2' sx={largeScreenCart.checkoutButtonText}>
                            Survey
                        </Typography>
                    </Stack>
                </Stack>
                <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams} />
                {openSurveyModal &&<SurveyModal openSurveyModal={openSurveyModal} setOpenSurveyModal={setOpenSurveyModal} surveyQuestions={surveyQuestions} location_id={specificRestaurantData.loc_id} tempSessionId={session_id}/>}
            </>
        )
    }

    export default History